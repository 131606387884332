import ar_icon from "../images/flags/ar.png";
import bg_icon from "../images/flags/bg.png";
import de_icon from "../images/flags/de.png";
import en_icon from "../images/flags/en.png";
import es_icon from "../images/flags/es.png";
import fr_icon from "../images/flags/fr.png";
import id_icon from "../images/flags/id.png";
import it_icon from "../images/flags/it.png";
import ja_icon from "../images/flags/ja.png";
import ko_icon from "../images/flags/ko.png";
import pl_icon from "../images/flags/pl.png";
import pt_icon from "../images/flags/pt.png";
import ru_icon from "../images/flags/ru.png";
import th_icon from "../images/flags/th.png";
import tl_icon from "../images/flags/tl.png";
import tr_icon from "../images/flags/tr.png";
import zh_icon from "../images/flags/zh.png";

export let languages = [
    {
        iso_code: "en",
        name: "English",
        native_name: "English",
        icon: en_icon
    },
    {
        iso_code: "es",
        name: "Spanish",
        native_name: "Español",
        icon: es_icon
    },
    {
        iso_code: "de",
        name: "German",
        native_name: "Deutsch",
        icon: de_icon
    },
    {
        iso_code: "fr",
        name: "French",
        native_name: "Français",
        icon: fr_icon
    },
    {
        iso_code: "ar",
        name: "Arabic",
        native_name: "العربية",
        icon: ar_icon
    },
    {
        iso_code: "bg",
        name: "Bulgarian",
        native_name: "български",
        icon: bg_icon
    },
    {
        iso_code: "id",
        name: "Indonesian",
        native_name: "Bahasa Indonesia",
        icon: id_icon
    },
    {
        iso_code: "it",
        name: "Italian",
        native_name: "Italiano",
        icon: it_icon
    },
    {
        iso_code: "ja",
        name: "Japanese",
        native_name: "日本語",
        icon: ja_icon
    },
    {
        iso_code: "ko",
        name: "Korean",
        native_name: "한국어",
        icon: ko_icon
    },
    {
        iso_code: "pl",
        name: "Polish",
        native_name: "Polski",
        icon: pl_icon
    },
    {
        iso_code: "pt",
        name: "Portuguese",
        native_name: "Português",
        icon: pt_icon
    },
    {
        iso_code: "ru",
        name: "Russian",
        native_name: "Русский",
        icon: ru_icon
    },
    {
        iso_code: "th",
        name: "Thai",
        native_name: "ไทย",
        icon: th_icon
    },
    {
        iso_code: "tl",
        name: "Tagalog",
        native_name: "Tagalog",
        icon: tl_icon
    },
    {
        iso_code: "tr",
        name: "Turkish",
        native_name: "Türkçe",
        icon: tr_icon
    },
    {
        iso_code: "zh",
        name: "Chinese",
        native_name: "中文",
        icon: zh_icon
    },
];


export function get_default_language() {
    
    let prefered_language = navigator.language || navigator.userLanguage; 
    let language_code = prefered_language.substring(0, 2);
    let default_language = languages.find(lang => lang.iso_code === language_code);

    if (!default_language) {
        default_language = languages.find(lang => lang.iso_code === "en");
    }

    return default_language;
}